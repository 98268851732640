<template>
  <div>-</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.empty {
  color: #999;
  font-size: 12px;
  font-style: italic;
}
</style>
